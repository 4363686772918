import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Routes, RouterModule } from "@angular/router";

import { MainComponent } from "./main/main.component";
import { HomeComponent } from "./home/home.component";
import { ContactComponent } from "./contact/contact.component";

export const AppRoutes: Routes = [
	{
		path: "",
		component: MainComponent,
		children: [
			{
				path: "",
				redirectTo: "/home",
				pathMatch: "full",
			},
			{
				path: "home",
				component: HomeComponent,
			},
		],
	},

	{
		path: "**",
		redirectTo: "/home",
		pathMatch: "full",
	},
];

@NgModule({
	imports: [CommonModule, RouterModule.forRoot(AppRoutes)],
	exports: [RouterModule],
	declarations: [],
})
export class AppRoutingModule {}
