// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  SendGrid: {
    API_KEY:
      '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'
  },

  firebase: {
    apiKey: 'AIzaSyA8a_uIE7wfSER3dZvSCIXj2YKO2wbFwm0',
    authDomain: 'smr-md-app.firebaseapp.com',
    databaseURL: 'https://smr-md-app.firebaseio.com',
    projectId: 'smr-md-app',
    storageBucket: 'smr-md-app.appspot.com',
    messagingSenderId: '96079713551',
    appId: '1:96079713551:web:630b46495e82740eca5a7a',
    measurementId: 'G-FWFMS3K6RD',
    functions: {
      appointmentScheduled:
        'https://us-central1-smr-md-app.cloudfunctions.net/appointmentScheduled'
    },
    secretKey: 'WkaplZHwAQDdExgxeBRFwM0tzFBP36Nhd6f1Aedr'
  }
};
