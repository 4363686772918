import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    responseType: 'text',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class HubspotService {
  baseUrl = 'https://api.hsforms.com/submissions/v3/integration/submit';
  portalId = '7723865';
  subscribeId = '';
  contactId = '25a46c98-359e-4597-a0f8-d4ba9bc40a15';

  constructor(private http: HttpClient) {}
  // * urls
  // newsletter subscription
  get subscribeUrl(): string {
    return `${this.baseUrl}/${this.portalId}/${this.subscribeId}`;
  }
  // Contact
  get contactUrl(): string {
    return `${this.baseUrl}/${this.portalId}/${this.contactId}`;
  }

  //submission posts
  subscribe(payload) {
    const final_payload = JSON.stringify(payload);
    // console.log('subscribe email data:', final_payload);
    return this.http.post(this.subscribeUrl, final_payload, httpOptions);
  }
  contact(payload) {
    const final_payload = JSON.stringify(payload);
    // console.log('subscribe email data:', final_payload);
    return this.http.post(this.contactUrl, final_payload, httpOptions);
  }
}
