/*
 * Send message
 * Used in another component.
 */
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { HubspotService } from '../../../service/hubspot.service';

export type Message = {
  f_name: string;
  l_name: string;
  email_address: string;
  message: string;
};

@Component({
  selector: '[angly-sendMessage]',
  templateUrl: './sendMessage.component.html',
  styleUrls: ['./sendMessage.component.scss'],
})
export class SendMessageComponent implements OnInit {
  sendMessageForm: FormGroup;
  hbspt: any;
  date = Date.now();
  contactSubmitted = false;
  postSuccess = false;
  postError = false;

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private hubspot: HubspotService
  ) {
    this.sendMessageForm = this.fb.group({
      fields: this.fb.group({
        fname: ['', [Validators.required]],
        lname: ['', [Validators.required]],
        email: ['', [Validators.required]],
        message: ['', [Validators.required]],
      }),
    });
  }

  ngOnInit() {}

  /*
   * sendMessage
   */
  sendMessage(values: Message) {
    this.contactSubmitted = true;
    const dataObj: any = {
      submittedAt: this.date,
      fields: [
        {
          name: 'email',
          value: this.sendMessageForm['controls'].fields['controls'].email
            .value,
        },
        {
          name: 'firstname',
          value: this.sendMessageForm['controls'].fields['controls'].fname
            .value,
        },
        {
          name: 'lastname',
          value: this.sendMessageForm['controls'].fields['controls'].lname
            .value,
        },
        {
          name: 'message',
          value: this.sendMessageForm['controls'].fields['controls'].message
            .value,
        },
      ],
      context: {
        pageUri: 'smr-md',
        pageName: 'landing',
      },
    };
    this.hubspot.contact(dataObj).subscribe(
      (data) => {
        // console.log('Post request is successful ', data);
        alert('Thanks for submitting the form.  We will get back to shortly.');
        return (this.postSuccess = true);
      },
      (error) => {
        // console.log('Error ', error);
        alert(error);
        this.contactSubmitted = false;
        this.sendMessageForm['controls'].fields.reset({
          email: '',
          fname: '',
          lname: '',
          message: '',
        });
        return (this.postError = true);
      }
    );
  }
}
